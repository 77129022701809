.hero-container {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.hero-sub-heading {
  font-size: 20px;
  font-weight: 500;
}

.growth {
  color: #f09020;
}

/* .img-fluid {
  color: #dcdcdc;
  opacity: 30%;
} */

.partner-container {
  background-color: white;
}

.features-container {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.features-heading {
  font-size: 1rem;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}

.bullet {
  height: 50px;

}

.pop-over-style {
  width: 500 !important;
  max-width: none;
  padding: 10px 0px;

}

.features-subtext {
  font-size: 0.8rem;
}

.features-head {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.globe-bg {
  height: 700px;

}


.view-text{
  margin-left: 15%;
  margin-right: 15%;
  margin-top: -5%;
}


@media screen and (max-width: 500px) {
  .view-text{
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 9%;
  }
  
  .hero-sub-heading {
    font-size: 17px !important;
    font-weight: 500;
  }

}

@media screen and (max-width: 450px) {
  .view-text{
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 9%;
  }
  
  

}

@media screen and (max-width: 800px) {
  .view-text{
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 9%;
  }
  
  .container-overlay {

    top: 0;
    left:0;
  
    width:100%;
  
    height: 100%;
    margin-top:10%;
    justify-content: center;
   
    /* background-color: #f09020; */
  
    /* Add any other custom styles as needed */
  
  }

}


@media screen and (max-width: 820px) {
  .view-text{
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 9%;
  }
  

}


@media screen and (min-width: 300px) {
  .container-overlay {

    top: 0;
  
    left:0;
  
    width:100%;
  
    height: 100%;
    margin-top:20% !important;
    justify-content: center;
   
    /* background-color: #f09020; */
  
    /* Add any other custom styles as needed */
  
  }

}

@media screen and (min-width: 500px) {
  .container-overlay {

    top: 0;
  
    left:0;
  
    width:100%;
  
    height: 100%;
    margin-top:10% !important;
    justify-content: center;
   
    /* background-color: #f09020; */
  
    /* Add any other custom styles as needed */
  
  }

}




#myVantaGlobe{
  transform:scaleX(-1.0);
  background-color:red;
  height:80%;
  /* left:-15%; */
  /* paddingLeft:-15%; */
  /* top: 10%; */
  width:100%;
  /* padding-top: 50%; */
}


.container-overlay {

  top: 0;

  left:0;

  width:100%;

  height: 100%;
  /* margin-top:7%; */
  justify-content: center;
 
  /* background-color: #f09020; */

  /* Add any other custom styles as needed */

}

.btn-heroCS {
  font-size: 13px;
  color: white;
  width: auto;
  border-radius: 40px;
  background: #f09020;
  transition: all 0.3s ease 0s;
  border: none;
  padding: 15px;
}

.btn-heroCS:hover {
  background: none;
  border: 2px solid #f09020;
  color: #f09020;
}

.testimonial-title {
  font-weight: 800;
}

.hero-bg {
  background: url("../../core//resources/assets/images/globe.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom -230px left -160px;
  transition: 0.3s;
}

@media screen and (max-width: 699px) {
  .hero-bg {
    background: url("../../core//resources/assets/images/globe.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom -190px left -190px;
    transition: 0.3s;

  }

}

@media screen and (max-width: 500px) {
  .hero-bg {
    background: url("../../core//resources/assets/images/globe.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom -5px left -140px;
    transition: 0.3s;

  }

  .promo-text {
    font-size: 12px !important;
  }

  .globe-grid-text {
  font-size:12px !important;
  }

  .arrow{
    width:10px !important;
    height:10px !important;
  }

  .canvas-for-globe{
    width:400px !important;
    height:300px !important;
  }
}




@media screen and (max-width: 400px) {
  .hero-bg {
    background: url("../../core//resources/assets/images/globe.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom -5px left -140px;
    transition: 0.3s;

  }

}

@media screen and (min-width: 992px) {
  .testimonial-content {
    text-align: left;
    font-size: 2rem;
    font-family: Addington;
  }

  .testimonial-name {
    text-align: left;
  }
}

@media screen and (min-width: 1200px) and (max-width:1317px) {
  .hero-sub-heading {
    font-size: 17px !important;
    font-weight: 500;
  }
}


@media screen and (min-width: 1518px)  {
  .hero-sub-heading {
    padding:0% 18% !important;
  }
}



@media screen and (max-width: 992px) {
  .testimonial-content {
    text-align: center;
    font-family: Addington;
  }

  .testimonial-name {
    text-align: center;
  }

  .testimonial-title {
    text-align: center;
  }

  .testimonial-arrow {
    text-align: center;
  }

  .canvas-for-globe{
    margin-left:-5%;
  }
}

@media screen and (max-width: 767px) {
  .canvas-for-globe{
    margin-left:-30%;
  }
}


@media screen and (max-width: 600px) {
  .canvas-for-globe{
    margin-left:-25%;
  }

}


@media screen and (max-width: 550px) {

  .canvas-for-globe{
    width:700px !important;
    height:550px !important;
    margin-left:-25% !important
  }
}

@media screen and (max-width: 549px) {

  .canvas-for-globe{
    width:550px !important;
    height:420px !important;
    margin-left:-5% !important
  }
}

@media screen and (max-width: 500px) {

  .canvas-for-globe{
    width:100% !important;
    height:100% !important;
    margin-left:0% !important
    /* margin-left:10% !important;
    margin-right:10% !important */
  }
}









/* @media screen and (min-width: 600px) and (max-width: 768px) {
  .hero-bg{
    background-position: bottom -60vh left -26vw;
  
}} */

.min-vh-50 {
  height: 80vh;
}

.globe {
  position: absolute;
  bottom: -40%;
  /* margin-top:"20%"; */
  left: -50%;
  /* border-radius: 53px; */
  /* border: 3px solid white; */
  width: 50vw;
  height: 95vh;
  /* z-index: -1; */
}



.first_view {
  position: relative;
  /* height:800px */
}

.growth {
  margin-top: "-10%"
}




/* .first-carousel{
  background: url('../../core/resources/assets/images/testimIMG.jpg');
  background-position: center;
  width:
} */


/* .carousel-arrow{
color: red;  
font-size: 24px; 
} */