.btn-footer {
  /* font-family: Raleway-SemiBold; */
  font-size: 13px;
  color: #4d4d4d;
  width: auto;
  line-height: 15px;
  padding:20px 20px  !important;
  border-radius: 40px;
  background: #f2f2f2;
  /* transition: all 0.3s ease 0s; */
  border: none;
  margin: 10px;
  padding: 20px 20px;
}

.btn-footer:hover {
  color: #4d4d4d;
  border:2px solid #4d4d4d;
  background:white;
  font-weight: bolder;
}

.btn-footer-country{
  width: auto;
  padding:10px 20px;
  background: #f2f2f2;
  border: none;
  margin: 10px;
  color: #000000;
  font-size: 13px;
  border-radius: 40px !important;
}

.btn-footer-country:hover{
  color: #4d4d4d;
  border:2px solid #4d4d4d;
  background:white;
  font-weight: bolder ;
}


.btn-footer-regulators{
  width: auto;
  padding:15px 20px;
  background: #f2f2f2;
  border: none;
  margin: 10px;
  color: #000000;
  font-size: 13px;
  border-radius: 40px !important;
}


.btn-footer-regulators:hover{
  color: #4d4d4d;
  border:2px solid #4d4d4d;
  background:white;
  font-style: bold;
  font-weight: bolder;
}

.langBtnFooter{
  border: unset;
  background-color: #f2f2f2;
  border-radius: 40px;
  width: unset;
  margin-right: auto;
  margin-left: auto;
  font-size:12px;
  padding: 20px 40px
}

.langBtnFooter:hover{
  color: #4d4d4d;
  border:2px solid #4d4d4d;
  background:white;
  font-weight: bolder;
}


@media only screen and (max-width: 600px) and (min-width: 445px){

  .card-height{
      height:400px !important;
  }

  .footer-nav-for-mobile{
    margin-left: -5%;
  }
}

@media only screen and (max-width: 700px){


  .footer-nav-for-mobile{
    margin-left: -6%;
  }
}
