.slick-dots li.slick-active button:before {
    color: #356DFF;
  }
  
  .slick-prev:before,
  .slick-next:before {
    color: #356DFF;
  }

  .bgblog{
    background-color: #f5f5f5;
  }

  .testcard{
    width: unset !important;
    margin: 1rem;
    
  }

  .bg-image{
    background:url("../../core/resources/assets/images/blogBgg.png");
   background-size: cover;
   background-position: center;
    background-repeat: no-repeat;
   
  }

  @font-face {
    font-family: Addington;
    src: url(../../core/resources/assets/font/AddingtonCF-Medium.otf);
  }
  
  .content{
    font-family: Addington CF  ;
  }


  .slick-prev, .slick-next {
    width: 50px!important;
  }

  .vr{
    margin-top: 4rem;
  }

body{
  font-size: 12px;
}

.read-more-less--more,
.read-more-less--less {
  color: #999;
}
