/* @font-face {
  font-family: Trap;
  src: url(../../core/resources/assets/font/Trap-Regular.woff2);
} */

/* @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&family=KoHo&family=Montserrat:wght@300&family=Outfit:wght@200;300;400;500;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap');
@font-face {
  font-family: 'Bhank';
  src: url('./lib/core/resources/assets/font/bhank/Bhank.otf') format('opentype');
  font-weight: bold;
  font-style: bold;
  font-display: swap;
  color: #4d4d4d;
}

.headerFont {
  font-family: 'Bhank', sans-serif; /* Fallback font family */
}



.App {
  font-family: "Onest";
  color: #4d4d4d;
  overflow: hidden;
}


.banner-for-promo{
background:#f09020;
/* height:70px; */
}

.blog-session{
  font-family:Georgia, 'Times New Roman', Times, serif;
}

.justify {
  text-align: justify;
  text-justify: inter-word;
}

.home-header{
font-size: 110px;
}

.modal-overlay {
  position: fixed;

  top: 0;

  left: 0;

  right: 0;

  bottom: 0;

  background-color:rgb(0, 0, 0);
  backdrop-filter: blur(5px);
  display: flex;

  align-items: center;

  justify-content: center;
}

.modal-content {
  justify-content: center;
  background-color:transparent;
  border:transparent;
  padding:
    20px;

  border-radius:
    4px;

  width:auto;

  text-align: center;
}

.custom-modal .modal-content{
  background-color:white;
  padding:
    20px;
  border-radius:
    4px;
  width:auto;
  text-align: left;
}

.modal-content h2 {

  margin-top:
    0;
}

.modal-content p {

  margin-bottom:
    20px;
}

.modal-content button {

  padding:
    10px 20px;
}
.header-margin{
margin-top:-10%
}

.hero-header-section{
  min-height: 90vh;
}

.globe-grid{
  height:auto;
}

@media only screen and (min-width: 370px ) and (max-width:399px){
  .landing-page-carousel {
    width:100% !important;
    background-color: rgb(19, 173, 224) !important;
  }
  .home-header{
    font-size: 70px;
    }
    .header-margin{
      margin-bottom:10%
      
      }
      .hero-header-section{
        min-height: auto !important;
        padding-bottom: 10%;
      }
      
}

@media only screen and (min-width: 0px ) and (max-width:369px){

  .home-header{
    font-size: 40px;
    }
    .header-margin{
      margin-top:0% !important
      
      }
      
        .hero-header-section{
          min-height: auto !important;
          padding-bottom: 10%;
        }
        
}

@media only screen and (min-width: 400px ) and (max-width:450px){
  .landing-page-carousel {
   width:370px !important;  
  }
  .home-header{
    font-size: 50px;
    }
    .header-margin{
      margin-top:0%
      }
      
        .hero-header-section{
          min-height: auto !important;
          padding-bottom: 10%;
        }
        
}
@media only screen and (min-width: 451px ) and (max-width:500px){
  .landing-page-carousel {
   width:450px !important;  
  }
  .home-header{
    font-size: 60px;
    }

    .header-margin{
      margin-top:0%
      
      }

        .hero-header-section{
          min-height: auto !important;
          padding-bottom: 10%;
        }
        
}

@media only screen and (min-width: 501px ) and (max-width:600px){
  .landing-page-carousel {
   width:500px !important;  
   margin-left:-3% ;
  }
  .home-header{
    font-size: 70px;
    }
    .header-margin{
      margin-top:4%
      
      }

    .hero-header-section{
      min-height: auto !important;
      padding-bottom: 10%;
    }
}


@media only screen and (min-width: 601px ) and (max-width:800px){
  .landing-page-carousel {
   width:550px !important;  
   margin-left:-10% ;
  }
  .home-header{
    font-size: 70px;
    }
    .header-margin{
      margin-top:4%
      
      }
    .hero-header-section{
      min-height: auto !important;
      padding-bottom: 10%;
    }
}

@media only screen and (min-width: 801px ) and (max-width:900px){
  .landing-page-carousel {
   width:600px !important;  
   margin-left:-10% ;
  }

  .header-margin{
    margin-top:4%
    
    }

  .home-header{
    font-size: 60px;
    }
    .hero-header-section{
      min-height: auto !important;
      padding-bottom: 10%;
    }
}

@media only screen and (min-width: 900px ) and (max-width:1200px){

  .home-header{
    font-size: 70px ;
    }
}

.vanta-canvas{
  transform: scaleX(-1),
}

@media only screen and (max-width:500px) {
 .for-mobile{
  height:300px !important;
  object-fit: cover;

 }

 .for-mobile-text{
  /* justify-content: center;
  text-align: center; */
  font-size: 30px;
 }
}

@media only screen and (min-width: 1201px ) and (max-width:1299px){

  .home-header{
    font-size: 70px ;
    }
}

@media only screen and (min-width: 1300px ) and (max-width:1450px){

  .home-header{
    font-size: 80px ;
    }
}

@media only screen and (min-width: 1451px ) and (max-width:1780px){

  .home-header{
    font-size: 90px ;
    }
}


/* @media only screen and (max-width: 400px) {
  .landing-page-carousel {
   width:500px !important;
   
  }
} */


/* .logo-card{
  width: 400px;
  height: 400px; 
  display: flex; 
  justify-contenter:center;
  align-items: center; */
  /* } */

  .col-bg{
    background-color: rgba(247, 246, 246, 0.822);
    
  }

  .collapse-promo{
    /* margin-top:-2.7%; */
    /* padding-right:20px; */
 
  }

  .list-border-left{
    border-bottom: none;
    border-right:none;
    border-top: none;
    border-left: 10px solid #f09020;

  }

  .emphasis{
    color:#f09020;
  }

