
 #swiperPage .swiper-pagination {
    /* background-color: red; */
    opacity: 1;
    /* background:red; */
    margin-left: 17% !important;
  }

  #swiperPagetab .swiper-pagination {
    margin-left: 15% !important;
    /* background:red; */
  }

  #swiperPagemobile .swiper-pagination {
    margin-left: 13% !important;
    /* background:rgb(25, 0, 255); */
  }

  #swiperPageSmallScreen .swiper-pagination {
    margin-left: 1% !important;
    /* background:rgb(255, 217, 0); */
  }

  @media only screen and (max-width:500px){

    .swiper-pagination {
    /* background-color: red; */
    opacity: 1;
    margin-left: -3% !important;
  }
  }