
  .swiper-pagination {
    /* background-color: red; */
    opacity: 1;
    margin-left: 20% ;
    /* margin-right: 25% !important; */
  }

  #swiperPageSmall .swiper-pagination {
    margin-left: 1% !important;
    /* background:rgb(255, 217, 0); */
  }
 
  .swiper-container {
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    direction: rtl; /* set right-to-left direction */
  }