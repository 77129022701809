/* .footer-card-radius{
    border-bottom-left-radius: 50px !important;
} */

.card-footer:last-child {
    border-radius: 0 0 20px 20px !important;
}

@media only screen and (min-width: 601px) {
    .third{
        display:none;
    }

    .second{
        display:none;
    }
}




@media only screen and (max-width: 600px) and (min-width: 445px){

    .card-height{
        height:400px !important;
    }
}
