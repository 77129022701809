header.masthead {
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-bottom: 3rem;
  background: #a3bded;
  background: -webkit-linear-gradient(-20deg, #a3bded 0%, #6991c7 100%);
  background: -moz-linear-gradient(-20deg, #a3bded 0%, #6991c7 100%);
  background: linear-gradient(-20deg, #a3bded 0%, #6991c7 100%);
}

header.masthead .site-heading {
  padding: 100px 0 50px;
  color: #fff;
}

header.masthead .site-heading h1 {
  font-size: 2.3rem;
}

header.masthead .site-heading .subheading {
  display: block;
  font-weight: 300;
  margin: 0.625rem 0 0;
  color: #fff;
}

ul.job-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.job-list > li.job-preview {
  background: #fff;
  border: none;
  border-bottom: 1px solid #d7e2e9;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  border-radius: 0.4rem;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  float: left;
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

ul.job-list > li.job-preview:hover {
  cursor: pointer;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
}

.job-title {
  margin-top: 0.6rem;
  font-size: 1.5rem;
}

.company {
  color: #96a4b1;
  font-size: 1rem;
}

.job-preview .btn {
  margin-top: 1.1rem;
}

.btn-apply {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  /* font-family:; */
  letter-spacing: 1px;
  background: #F09020 0% 0% no-repeat padding-box;
  color: white;
  border:none;
  padding: 0.3rem 1.5rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
}

.btn-apply:hover {
  background-color: #ffffff;
  color: #f09020;
  border: 2px solid #f09020;
}

.btn-apply:focus {
  background-color: #ffffff;
  color: #f09020;
  border: 2px solid #f09020;
}

.career-header{
  font-weight: bolder;
}

.header{
  margin-left:10%;
  margin-right:10%;
}


@media only screen and (max-width: 768px) {
  .header{
    margin-left:0;
    margin-right:0;
    text-align: center;
  }

}
@media (max-width: 575px) {
  .job-preview .content {
    width: 100%;
  }
}

.job-describtion {
  margin-top: 1.5rem;
  font-size: 0.8rem;
}

@media screen and (max-width: 600px) {
  .jobb{
    flex-wrap: wrap;
  }

}