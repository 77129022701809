.navfont {
  font-size: 0.8rem;
  color: #4d4d4d;
  font-weight: 500;
}



.navbar-nav > li:hover,
.navbar-default .navbar-nav > li:focus {
  background: #f2f2f2;
  color: black;
  border-radius: 40px;
}

.nav-link.active{
  border-bottom: 1px solid #f09020 !important;

}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  background-color: #f5f5f5;
}

.nav-list-style{
  list-style: none;
}


.list-group-item.active {
  background-color: #f5f5f5;
  border: white;
  color: #4d4d4d;
}

.list-group-item{
  background-color: #f5f5f5;
}


@media only screen and (max-width:992px) {
  .nav-link.active{
    border-bottom: 1px solid #f09020 !important;
    /* background:#f3f3f3; */
    /* border-radius: 10px; */
    /* padding:5px 25px 5px 25px; */
    /* color:#fda43d; */
  }
}

.bounce {
  position: relative;
  animation: move 2.5s ease-in-out infinite;
}

@keyframes move {
  0% {
    left: 0;
  }
  50% {
    left: 5px;
  }
  100% {
    left: 0;
  }
}