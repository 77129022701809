.how-it-works-bg {
    background-image: url('../../core/resources/assets/images/img_how_it_works.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.glass {
    /* From https://css.glass */
    background: #4f4f4f6b;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #4F4F4F;
}


.borderColor {
    border-color: #F09020 !important;
}