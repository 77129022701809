
.btn-product {
    /* font-family: Raleway-SemiBold; */
    font-size: 15px;
    color: white;
    /* width: 70%; */
    line-height: 15px;
    border-radius: 40px;
    background: #f09020;
    border: none;
    height: 50px;
    padding:0px 25px;
  }


  .anim:hover{
    background-color: #f2f2f2;
    border-radius: 40px;
    transition: all 0.5s ease-in-out;
    transform: translateY(-15px);
    height:auto;
    /* transform: skewX(30deg); */
  }
  

  .anim:hover .btn-product{
    padding:0px;
    padding-left: 9px;
    /* transform: skewX(30deg); */
  }

  .anim:hover::before {
    left: 0;
  }

  .mail{display: none;
    cursor: pointer;
    transition: all 2.0s ease-out ;
  }
  .anim:hover .mail  {
    display: revert;
    flex-direction: column;
    transition: all 2.0s ease-in-out;
    transition-duration: 8s;

  }

  .anim:hover .btn-product{
    background: none;
    color: #f09020;
    font-weight: 700;
    width: auto;
    text-decoration: underline;
  }


  .third-party-info-row.show {
    display: flex;
    background: #f2f2f2;
    border-radius: 40px;
    transition: all 1.0s ease-in;
    transform: translateY(-15px);
    height:280px;
  }

  .third-party-info-row{
    display:none;
  }

  .stand-alone-row.show {
    display: none;
  }


  @media screen and (max-width: 992px) {
    .third-party-info-row.show {
      height:auto;
    }

  }

  @media screen and (max-width: 1199px) {


    .row-border{
      border:none !important;
    }
  
  }

  .row-border{
    border-left: 1px solid rgb(223, 223, 223);
  }

