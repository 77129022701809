.growth-heading {
  font-size: 1.5rem;
  font-weight: bold;
}

.growth-subtext {
  text-align: center;
  margin-left: 10rem;
  margin-right: 10rem;
}

.bg-card {
  background-color: #f2f2f2;
  /* height: 35rem; */
}

.about-content {
  /* font-family: Trap; */
  color:#4d4d4d;
  font-size:25px !important ;
  padding:0px 20px !important;
  /* font-weight: 100 !important; */
}

.title{
  padding:10px 20px !important;
  
}

@font-face {
  font-family: Addington;
  src: url(../../core/resources/assets/font/AddingtonCF-Medium.otf);
}

@media only screen and (max-width: 600px) {
  .bg-card {
    height: auto;
    /* background:red; */
  }
}

@media only screen and (max-width:1199px) {
  .bg-card {
    height: 42rem;
    /* background:40rem; */
  }
}
@media only screen and (max-width: 990px) {
  .bg-card {
    height: auto;
    /* background:40rem; */
  }
}

.accelerating-growth-row{
  margin-left: 15%;
  margin-right: 15%;
}


@media only screen and (max-width: 1174px) {
  .accelerating-growth-row{
    margin-left: 5%;
    margin-right: 5%;
  }
    
}

@media only screen and (max-width: 770px) {
  .accelerating-growth-row{
    margin-left: -5%;
    margin-right: -5%;
  }
    
}
.accelerating-growth-content-col{
  margin-top:-8%;
}
.padding-space{
  padding:40px;
 
}

@media only screen and (max-width: 460px) {
  .padding-space{
    padding:0px;
   
  }
    
}
/* @media screen and (max-width: 1024px) {
  .bg-card {
    height: 30rem;
  }
} */

/* .vision-card{
  height:600px
} */

